<template>
    <div 
        style="overflow: hidden; border-bottom: 1px solid #DDE2E4; padding-bottom: 10px; position: relative;"
        :style="{height: localHeight, maxHeight: localHeight}"
    >
        <h3 v-if="!chartData?.length" style="position: absolute; left: 0; top: 0;">Data not found, try selecting a different filter</h3>
        <canvas :id="chartId ? chartId : 'measurments_chart'" style="width: 100%;height: 100%;"></canvas>
    </div>
</template>

<script>
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { devicesDataCategories } from '@/config';

export default {
    props: ["chartData", "category", "dateSort", "height", "chartId", "dataFontSize", "delta", "enableMin", "enableMax"],
    data(){
        return {
            localHeight: this.height ? this.height : '400px',
            chart: null,
            categories: devicesDataCategories,
        }
    },
    computed: {
        filteredData(){
            // let filteredByCategoryData = [...this.chartData.filter(item => item.type === this.categories[this.category].category)];
            let filteredByCategoryData = [...this.chartData];
            let returnedData = [];
            let delta = 0;
            // let filterBy = filteredByCategoryData.sort((a, b) => (new Date(a.timestamp)).getTime() - (new Date(b.timestamp)).getTime())[filteredByCategoryData.length - 1];

            let filteredDate = new Date();
            
            if (this.dateSort) {
                if(this.dateSort === 'Day' || this.dateSort === '1 day' || this.dateSort === '2 days' || this.dateSort === '3 days' || this.dateSort === '7 days'){
                    if(this.dateSort === 'Day' || this.dateSort === '1 day'){
                        filteredDate.setDate( filteredDate.getDate() - 1 );
                    }
                    if(this.dateSort === '2 day'){
                        filteredDate.setDate( filteredDate.getDate() - 2 );
                    }
                    if(this.dateSort === '3 day'){
                        filteredDate.setDate( filteredDate.getDate() - 3 );
                    }
                    if(this.dateSort === '7 day'){
                        filteredDate.setDate( filteredDate.getDate() - 7 );
                    }
                    // === new Date(filterBy.timestamp).getDate()
                    // .getDate()
                    returnedData = filteredByCategoryData.reverse().filter(item => new Date(item.timestamp) >= new Date(filteredDate)).reverse();
                    delta = this.delta ? this.delta : Math.floor(returnedData.length / 10);
                }

                if(this.dateSort === 'Month' || this.dateSort === '15 days' || this.dateSort === '60 days'){
                    // === new Date(filterBy.timestamp).getMonth()

                    if(this.dateSort === 'Month'){
                        filteredDate.setDate( filteredDate.getDate() - 30 );
                    }
                    if(this.dateSort === '15 days'){
                        filteredDate.setDate( filteredDate.getDate() - 15 );
                    }
                    if(this.dateSort === '60 days'){
                        filteredDate.setDate( filteredDate.getDate() - 60 );
                    }

                    // .getMonth()
                    returnedData = filteredByCategoryData.filter(item => new Date(item.timestamp) >= new Date(filteredDate));
                    delta = this.delta ? this.delta : filteredByCategoryData.length > 15 ? Math.floor(returnedData.length / 15) : Math.floor(returnedData.length / 10);
                }

                if(this.dateSort === 'Year' || this.dateSort === '90 days'){
                    //  === new Date(filterBy.timestamp).getFullYear()
                    if(this.dateSort === 'Year'){
                        filteredDate.setFullYear( filteredDate.getFullYear() - 1 );
                    }

                    if(this.dateSort === '90 days'){
                        filteredDate.setDate( filteredDate.getDate() - 90 );
                    }
                    
                    returnedData = filteredByCategoryData.filter(item => new Date(item.timestamp) >= new Date(filteredDate));
                    delta = this.delta ? this.delta : filteredByCategoryData.length > 30 ? Math.floor(returnedData.length / 30) : Math.floor(returnedData.length / 15);
                }
            } else {
                return filteredByCategoryData;
            }

            let arr = [];
            for (let i = 0; i < returnedData.length; i = i + delta) {
                arr.push(returnedData[i]);
            }

            if(arr[arr.length-1].timestamp !== returnedData[returnedData.length-1].timestamp){
                arr.push(returnedData[returnedData.length-1]);
            }

            return arr.sort((a, b) => (new Date(a.timestamp)).getTime() - (new Date(b.timestamp)).getTime());
        }
    },
    watch: {
        dateSort(){
            this.chart.destroy();
            this.renderChart();
        },
        enableMin() {
            this.chart.destroy();
            this.renderChart();
        },
        enableMax() {
            this.chart.destroy();
            this.renderChart();
        },
    },
    methods: {
        getLabels(){
            function numbNoZero(numb){
                if(String(numb).length < 2){
                    return '0'+String(numb)
                } else {
                    return numb;
                }
            }

            if(this.dateSort === 'Day'){
                return [...this.filteredData.map(item => `${numbNoZero(new Date(item.timestamp).getHours())}:${numbNoZero(new Date(item.timestamp).getMinutes())}`)]
            } else {
                return [...this.filteredData.map(item => new Date(item.timestamp).toLocaleDateString())]
            }
        },
        renderChart(){
            const ctx = document.getElementById(this.chartId ? this.chartId : 'measurments_chart');
            const horizonalLinePlugin = {
                afterDraw: function(chartInstance) {
                    let yScale = chartInstance.scales["y"];
                    let canvas = chartInstance.canvas;
                    let ctx = canvas.getContext('2d');
                    let hh = chartInstance.options.horizontalLine && chartInstance.options.horizontalLine.filter(el => el);

                    if (hh) {
                        for (let index = 0; index < hh.length; index++) {
                            let { width, color, text, y } = hh[index];
                            let yValue = y ? yScale.getPixelForValue(y) : 0;

                            ctx.lineWidth = width || 1.5;

                            if (yValue) {
                                ctx.beginPath();
                                ctx.moveTo(0, yValue);
                                ctx.lineTo(canvas.width, yValue);
                                ctx.strokeStyle = color || "rgba(169,169,169, .6)";
                                ctx.stroke();
                            }

                            if (text) {
                                ctx.fillStyle = color || "rgba(169,169,169, .6)";
                                ctx.fillText(text, 0, yValue - ctx.lineWidth);
                            }
                        }

                        return;
                    };
                }
            };

            if (this.chartData){
                const self = this;
                const data = [...this.filteredData.map(item => item.value)];

                this.chart = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels: this.getLabels(),
                        datasets: [{
                            label: this.categories[this.category] ? this.categories[this.category].category : this.category,
                            data: data,
                            backgroundColor: [
                                '#E12525',
                            ],
                            borderColor: [
                                '#F4BBBB',
                            ],
                            borderWidth: this.dateSort === 'Year' ? 1 : 1.5,
                            pointRadius: this.dateSort === 'Year' ? 3 : 7,
                            pointBackgroundColor: '#ffffff',
                            pointBorderColor: '#E12525'
                        }]
                    },
                    plugins: [ChartDataLabels, horizonalLinePlugin],
                    options: {
                        horizontalLine: [
                            (this.enableMax ? { y: Math.max(...data), text: "max", color: '#e12525', width: 1.5 } : false), 
                            (this.enableMin ? { y: Math.min(...data), text: "min", color: '#10bb0c', width: 1.5 } : false)
                        ],
                        responsive: true,
                        maintainAspectRatio: false,
                        // clamp: true,
                        // clip: true,
                        // layout: {
                        //     padding: 10
                        // },
                        scales: {
                            y: {
                                display: false,
                            },
                            x: {
                                grid: {
                                    borderDash: [4, 4],
                                },
                                ticks: {
                                    font: {
                                        family: 'Noto Sans',
                                        size: 14,
                                        weight: 600,
                                    },
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                display: false,
                            },
                            datalabels: {
                                color: '#E12525',
                                align: 'top',
                                padding: {
                                    bottom: this.dateSort === 'Year' ? 16 : 12,
                                },
                                display: 'auto',
                                textAlign: 'center',
                                font: {
                                    family: 'Noto Sans',
                                    size: this.dataFontSize ? this.dataFontSize : this.dateSort === 'Year' ? 12 : 16,
                                    weight: 500
                                },
                                formatter: function(value, context) {
                                    return value + (self.categories[self.category] ? self.categories[self.category].unit : self.category);
                                }
                            }
                        }
                    },
                });

                this.$emit('setCurrent', this.filteredData);
            }
        }
    },
    mounted(){
        this.renderChart();
    }
}
</script>